<template>
  <div class="das_top_cont">
    <div class="history_box">
      <div class="accountright_cont_box">
        <div>
          <div class="item_title">
            <div class="history_box-head">Online Disclaimer</div>
            <div class="item_cont_txt-head">
              Online Disclaimer Regarding Information
            </div>
            <h3 class="history_box-h4">
              The information from or through this site is provided “as-is,” “as
              available,” and all warranties, expressed or implied, are
              disclaimed (including but not limited to the disclaimer of any
              implied warranties of merchantability and fitness for a particular
              purpose). The information may contain errors, problems or other
              limitations. Our sole and entire maximum liability for any
              inaccurate information, for any reason, and user’s sole and
              exclusive remedy for any cause whatsoever, shall be limited to the
              amount paid by the customer for the information received (if any).
              We are not liable for any indirect, special, incidental, or
              consequential damages (including damages for loss of business,
              loss of profits, litigation, or the like). Whether based on breach
              of contract, breach of warranty, tort (including negligence),
              product liability or otherwise, even if advised of the possibility
              of such damage. The limitations of damages set forth above are
              fundamental elements of the basis of this agreement between you
              and company search incorporated. We would not provide this site
              and information without such limitations. No representations,
              warranties or guarantees whatsoever are made as to the accuracy,
              adequacy, reliability, timeliness, completeness, suitability or
              applicability of the information to a particular situation. All
              responsibility and liability for any damages caused by viruses
              contained within the electronic files of this site are disclaimed.
              All terms and conditions with respect to this site are governed by
              a Terms of the Service Agreement.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Online Disclaimer</div>

            <h3 class="history_box-h4">
              The content, organization, graphics, design, compilation, magnetic
              translation, digital conversion and other matters related to the
              Site are protected under applicable copyrights, trademarks and
              other proprietary (including but not limited to intellectual
              property) rights. The copying, redistribution, use or publication
              by you of any such matters or any part of the Site, is strictly
              prohibited. You do not acquire ownership rights to any content,
              document or other materials viewed through the Site. The posting
              of information or materials on the Site does not constitute a
              waiver of any right in such information and materials. Some of the
              content on the site is the copyrighted work of third parties.
              Unless otherwise stated, the logo, copyright, database and similar
              rights in all published material on the CSIbizInfo website are
              owned by ‘Company Search Incorporated’. You are only permitted to
              print or download from this material for only your internal
              corporate or personal use. None of this material may be used for
              any commercial or public use. No part of the CSIbizInfo website or
              any material appearing on the site may be reproduced, stored in or
              transmitted to any other website without written permission of
              Company Search Incorporated. You may send your requests to
              republish any material to request@CSIbizInfo.com
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Terms of Use Agreement</div>

            <h3 class="history_box-h4">
              By using our site, you are agreeing to comply with and be bound by
              the following terms of use. Please review the following terms
              carefully. If you do not agree to these terms, you should not use
              this site. The term ‘CSIbizInfo’ or “us” or “we” or “our” refers
              to ‘Company Search Incorporated’, the owner of the Website. The
              term “you” refers to the user or viewer of our website.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Acceptance of Agreement</div>

            <h3 class="history_box-h4">
              You agree to the terms and conditions outlined in this Terms of
              Use Agreement ("Agreement") with respect to our website (the
              "Site"). This Agreement constitutes the entire and only agreement
              between us and you, and supersedes all prior or contemporaneous
              agreements, representations, warranties and understandings with
              respect to the Site, the content, products or services provided by
              or through the Site, and the subject matter of this Agreement.
              This Agreement may be amended at any time, at our absolute
              discretion, from time to time without specific notice to you. The
              latest Agreement will be posted on the Site, and you should review
              this Agreement prior to using the Site.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Service Marks</div>

            <h3 class="history_box-h4">
              ‘CSIbizInfo.com’ and others are our service marks, registered
              service marks or trademarks. Other product and company names
              mentioned on the Site may be trademarks of their respective
              owners.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">
              Limited License; Permitted Uses
            </div>

            <h3 class="history_box-h4">
              You are granted a non-exclusive, non-transferable, revocable
              license (a) to access and use the Site strictly in accordance with
              this Agreement; (b) to use the Site solely for internal, personal,
              non-commercial purposes; and (c) to print out discrete information
              (or electronic version) from the Site solely for internal,
              personal, non-commercial purposes and provided that you maintain
              all copyright and other policies contained therein. No print out
              or electronic version of any part of the Site or its contents may
              be used by you in any litigation or arbitration matter whatsoever
              under any circumstances.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">
              Restrictions and Prohibitions on Use
            </div>

            <h3 class="history_box-h4">
              Your license for access and use of the Site and any information,
              materials or documents (collectively defined as “Content and
              Materials”) therein are subject to the following restrictions and
              prohibitions on use: You may not (a) copy, print (except for the
              express limited purpose permitted above), republish, display,
              distribute, transmit, sell, rent, lease, loan or otherwise make
              available in any form or by any means all or any portion of the
              Site or any Content and Materials retrieved there from; (b) use
              the Site or any materials obtained from the Site to develop, of as
              a component of, any information, storage and retrieval system,
              database, information base, or similar resource (in any media now
              existing or hereafter developed), that is offered for commercial
              distribution of any kind, including through sale, license, lease,
              rental, subscription, or any other commercial distribution
              mechanism; (c) create compilations or derivative works of any
              Content and Materials from the Site; (d) use any Content and
              Materials from the Site in any manner that may infringe any
              copyright, intellectual property right, proprietary right, or
              property right of us or any third parties; (e) remove, change or
              obscure any copyright notice or other proprietary notice or terms
              of use contained in the Site; (f) make any portion of the Site
              available through any timesharing system, service bureau, the
              Internet or any other technology now existing or developed in the
              future; (g) remove, decompile, disassemble or reverse engineer any
              Site software or use any network monitoring or discovery software
              to determine the Site architecture; (h) use any automatic or
              manual process to harvest information from the Site; (i) use the
              Site for the purpose of gathering information for or transmitting
              (1) unsolicited commercial email; (2) email that makes use of
              headers, invalid or nonexistent domain names, or other means of
              deceptive addressing; and (3) unsolicited telephone calls or
              facsimile transmissions; (j) use the Site in a manner that
              violates any state or federal law regulating email, facsimile
              transmissions or telephone solicitations; and (k) export or
              re-export the Site or any portion thereof, or any software
              available on or through the Site.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Forms, Agreements & Documents</div>

            <h3 class="history_box-h4">
              We may make available through the Site or through other Websites
              sample and actual forms, checklists, business documents and legal
              documents (collectively, “Documents”). All Documents are provided
              on a non-exclusive license basis only for your personal one-time
              use for non-commercial purposes, without any right to re-license,
              sublicense, distribute, assign or transfer such license. Documents
              are provided for a charge and without any representations or
              warranties, express or implied, as to their suitability, legal
              effect, completeness, currentness, accuracy, and/or
              appropriateness. The Documents are provided “as is”, “as
              available”, and with “all faults”, and we and any provider of the
              Documents disclaim any warranties, including but not limited to
              the warranties of merchantability and fitness for a particular
              purpose. The Documents may be inappropriate for your particular
              circumstances. Furthermore, state or provincial laws may require
              different or additional provisions to ensure the desired result.
              You should consult with legal counsel to determine the appropriate
              legal or business documents necessary for your particular
              transactions, as the Documents are only samples and may not be
              applicable to a particular situation. Some Documents are public
              domain forms or available from public records.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">
              No Legal Advice or Attorney-Client Relationship
            </div>

            <h3 class="history_box-h4">
              Information contained on or made available through the Site is not
              intended to and does not constitute legal advice, recommendations,
              mediation or counseling under any circumstance and no
              attorney-client relationship is formed. We do not warrant or
              guarantee the accurateness, completeness, adequacy or currentness
              of the information contained in or linked to the Site. Your use of
              information on the Site or materials linked to the Site is
              entirely at your own risk. We are not a law firm and the Site is
              not a lawyer referral service.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Linking to the Site</div>

            <h3 class="history_box-h4">
              You may provide links to the Site, provided (a) that you do not
              remove or obscure, by framing or otherwise, advertisements, the
              copyright notice, or other notices on the Site, (b) your site does
              not engage in illegal or pornographic activities, and (c) you
              discontinue providing links to the Site immediately upon request
              by us.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Advertisers</div>

            <h3 class="history_box-h4">
              The Site may contain advertising and sponsorships. Advertisers and
              sponsors are responsible for ensuring that material submitted for
              inclusion on the Site is accurate and complies with applicable
              laws. We are not responsible for the illegality or any error,
              inaccuracy or problem in the advertiser’s or sponsor’s materials.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Registration</div>

            <h3 class="history_box-h4">
              Certain sections of, or offerings from, the Site may require you
              to register. If registration is requested, you agree to provide us
              with accurate, complete registration information. Your
              registration must be done using your real name and accurate
              information. Each registration is for your personal use only and
              not on behalf of any other person or entity. We do not permit: (a)
              any other person using the registered sections under your name; or
              (b) access through a single name being made available to multiple
              users on a network. You are responsible for preventing such
              unauthorized use and can be held accountable. It is prohibited
              and, consequently, punishable by law when the user gives false
              personal details, if registering at the website or other ways of
              communication.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Database</div>

            <h3 class="history_box-h4">
              We source the information in our databases through a variety of
              means including: directly from government websites, from publicly
              available datasets, through Freedom of Information requests as
              well as data partners, with or without APIs. All of the data is
              converted into a usable resource enabling the user an easy
              workable search engine search engine We spend a lot of time,
              effort, and even money in getting this data and turning it into a
              usable resource workable and highly usable resource. We do not
              claim any rights over the information we receive from our any of
              our sources, though claim rights over our database of this
              information.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Availability of Products</div>

            <h3 class="history_box-h4">
              Access and/or Information on the Site might not be available in
              your location. If that occurs, it has to do with local laws and
              prohibitions. When that is the case, please contact
              request@CSIbizInfo.com for more information.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">
              Errors, Corrections and Changes
            </div>

            <h3 class="history_box-h4">
              We do not represent or warrant that the Site will be error-free,
              free of viruses or other harmful components, or that defects will
              be corrected. We do not represent or warrant that the information
              available on or through the Site will be correct, accurate, timely
              or otherwise reliable. We may make changes to the features,
              functionality or content of the Site at any time. We reserve the
              right in our sole discretion to edit or delete any documents,
              information or other content appearing on the Site.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Third Party Content</div>

            <h3 class="history_box-h4">
              Third party content may appear on the Site or may be accessible
              via links from the Site. We are not responsible for and assume no
              liability for any mistakes, misstatements of law, defamation,
              omissions, falsehood, obscenity, pornography or profanity in the
              statements, opinions, representations or any other form of content
              on the Site. You understand that the information and opinions in
              the third-party content represent solely the thoughts of the
              author and is neither endorsed by nor does it necessarily reflect
              our belief.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Unlawful Activity</div>

            <h3 class="history_box-h4">
              We reserve the right to investigate complaints or reported
              violations of this Agreement and to take any action we deem
              appropriate, including but not limited to reporting any suspected
              unlawful activity to law enforcement officials, regulators, or
              other third parties and disclosing any information necessary or
              appropriate to such persons or entities relating to your profile,
              email addresses, usage history, posted materials, IP addresses and
              traffic information. The user should not to do anything to harm
              CSIbizInfo’s reputation.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Indemnification</div>

            <h3 class="history_box-h4">
              The user agrees to indemnify, defend and hold us and our partners,
              agents, officers, directors, employees, subcontractors,
              successors, assigns, third party suppliers of information and
              documents, attorneys, advertisers, product and service providers,
              and affiliates (collectively, "Affiliated Parties") harmless from
              any liability, loss, claim and expense, including reasonable
              attorney's fees, related toyour violation of this Agreement or use
              of the Site.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Nontransferable</div>

            <h3 class="history_box-h4">
              Your right to use the Site is not transferable or assignable. Any
              password or right given to you to obtain information or documents
              is not transferable or assignable.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Limitation of Liability</div>

            <h3 class="history_box-h4">
              (a) We and any Affiliated Party shall not be liable for any loss,
              injury, claim, liability, or damage of any kind resulting in any
              way from (a) any errors in or omissions from the Site or any
              services or products obtainable there from, (b) the unavailability
              or interruption of the Site or any features thereof, (c) your use
              of the Site, (d) the content contained on the Site, or (e) any
              delay or failure in performance beyond the control of a Covered
              Party. (b) The aggregate liability of us and the affiliated
              parties in connection with any claim arising out of or relating to
              the site and/or the products, information, documents and services
              provided herein or hereby shall not exceed USD100. And that amount
              shall be in lieu of all other remedies which you may have against
              us and any affiliated party.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Use of Information</div>

            <h3 class="history_box-h4">
              We reserve the right, and you authorize us, to the use and
              assignment of all information regarding Site uses by you and all
              information provided by you in any manner consistent with our
              Privacy Policy. All remarks, suggestions, ideas, graphics, or
              other information communicated by you to us (collectively, a
              "Submission") will forever be our property. We will not be
              required to treat any Submission as confidential, and will not be
              liable for any ideas (including without limitation, product,
              service or advertising ideas) and will not incur any liability as
              a result of any similarities that may appear in our future
              products, services or operations. Without limitation, we will have
              exclusive ownership of all present and future existing rights to
              the Submission of every kind and nature everywhere. We will be
              entitled to use the Submission for any commercial or other purpose
              whatsoever, without compensation to you or any other person
              sending the Submission. You acknowledge that you are responsible
              for whatever material you submit, and you, not us, have full
              responsibility for the message, including its legality,
              reliability, appropriateness, originality, and copyright.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Third-Party Services</div>

            <h3 class="history_box-h4">
              We may allow access to or advertise certain third-party product or
              service providers ("Merchants") from which you may purchase
              certain goods or services. You understand that we do not operate
              or control the products or services offered by Merchants.
              Merchants are responsible for all aspects of order processing,
              fulfillment, billing and customer service. We are not a party to
              the transactions entered into between you and Merchants. You agree
              that use of or purchase from such Merchants is at your sole risk
              and is without warranties of any kind by us, expressed, implied or
              otherwise including warranties of title, fitness for purpose,
              merchantability or non-infringement. Under no circumstances are we
              liable for any damages arising from the transactions between you
              and merchants or for any information appearing on merchant sites
              or any other site linked to our site.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Third-Party Merchant Policies</div>

            <h3 class="history_box-h4">
              All rules, policies (including privacy policies) and operating
              procedures of Merchants will apply to you while on any Merchant
              sites. We are not responsible for information provided by you to
              Merchants. We and the Merchants are independent contractors and
              neither party has authority to make any representations or
              commitments on behalf of the other.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Privacy Policy</div>

            <h3 class="history_box-h4">
              Our Privacy Policy is designed to assist you in understanding how
              we collect and use the personal information you provide to us and
              to assist you in making informed decisions when using our site and
              our products and services. The user can only access or request
              personal data when he/she/they have a lawful basis for doing so.
              The user has to be able to demonstrate the existence of that
              lawful basis.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Payments</div>

            <h3 class="history_box-h4">
              You represent and warrant that if you are purchasing something
              from us or from Merchants that (i) any credit information you
              supply is true and complete, (ii) charges incurred by you will be
              honored by yourself or your credit card company, and (iii) you
              will pay the charges incurred by you at the posted prices,
              including any applicable taxes. When the user fails to pay the
              amount due, the full amount will become due with immediate effect
              and any remaining credits will be frozen until the amount has been
              settled. The user shall be liable to pay interest on the basis of
              6% above the prime annual base lending rate of the Bank of
              America. The user agrees to fully indemnify “CSIbizInfo” against
              all third-party cost incurred in the pursuit of payment. This
              includes any additional bank charges..
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Securities Laws</div>

            <h3 class="history_box-h4">
              The Site may include statements concerning our operations,
              prospects, strategies, financial condition, future economic
              performance and demand for our products or services, as well as
              our intentions, plans and objectives (particularly with respect to
              product and service offerings), that are forward-looking
              statements. These statements are based upon a number of
              assumptions and estimates which are subject to significant
              uncertainties, many of which are beyond our control. When used on
              our Site, words like "anticipates," "expects," "believes,"
              "estimates," "seeks," "plans," "intends," "will" and similar
              expressions are intended to identify forward-looking statements
              designed to fall within securities law safe harbors for
              forward-looking statements. The Site and the information contained
              herein do not constitute an offer or a solicitation of an offer
              for sale of any securities. None of the information contained
              herein is intended to be, and shall not be deemed to be,
              incorporated into any of our securities-related filings or
              documents.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Links to other Websites</div>

            <h3 class="history_box-h4">
              The Site contains links to other Websites. We are not responsible
              for the content, accuracy or opinions express in such Websites,
              and such Websites are not investigated, monitored or checked for
              accuracy or completeness by us. Inclusion of any linked Website on
              our Site does not imply approval or endorsement of the linked
              website by us. If you decide to leave our Site and access these
              third-party sites, you do so at your own risk.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">
              Copyrights and Copyright Agents
            </div>

            <h3 class="history_box-h4">
              We respect the intellectual property of others, and we ask you to
              do the same. If you believe that your work has been copied in a
              way that constitutes copyright infringement, please provide our
              Copyright Agent the following information:
              <br />1. An electronic or physical signature of the person
              authorized to act on behalf of the owner of the copyright
              interest; <br />2. A description of the copyrighted work that you
              claim has been infringed; <br />3. A description of where the
              material that you claim is infringing is located on the Site;
              <br />4. Your address, telephone number, and email address;
              <br />5. A statement by you that you have a good faith belief that
              the disputed use is not authorized by the copyright owner, its
              agent, or the law; and <br />6. A statement by you, made under
              penalty of perjury, that the above information in your Notice is
              accurate and that you are the copyright owner or authorized to act
              on the copyright owner's behalf. <br />Our Copyright Agent for
              Notice of claims of copyright infringement on the Site can be
              reached by directing an e-mail to the Copyright Agent at
              request@CSIbizInfo.com
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Information and Press Releases</div>

            <h3 class="history_box-h4">
              The Site contains information and press releases about us. We
              disclaim any duty or obligation to update this information or any
              press releases. Information about companies other than ours
              contained in the press release or otherwise, should not be relied
              upon as being provided or endorsed by us.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Legal Compliance</div>

            <h3 class="history_box-h4">
              You agree to comply with all applicable domestic and international
              laws, statutes, ordinances and regulations regarding your use of
              the Site and the Content and Materials provided therein.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Refund and Return Policy</div>

            <h3 class="history_box-h4">
              To the extent that you purchase any goods or services directly
              from us, we will refund you your purchase price within 30 days of
              you notifying us in writing of your desire for the refund,
              together with the reason for the request, with the product or
              service returned to us in substantially the same condition as when
              purchased. Please note, however, that certain products and
              services mentioned on our site are sold by third parties or are
              linked to third party websites, and we have no responsibility or
              liability for those products or services. You may request a refund
              by contacting us by email at request@CSIbizInfo.com You may obtain
              any additional information concerning our refund and return
              policy, including our mailing address, by contacting us at
              request@CSIbizInfo.com Any forthcoming bank charges though will be
              deducted.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">
              Warning Regarding Web Fraud and Phishing
            </div>

            <h3 class="history_box-h4">
              Our name, brand(s) and reputation may be misused or abused by
              imposters, fake websites and those engaging in “phishing” scams,
              seeking personal or confidential information. If you have any
              questions about the before-mentioned, have doubts about the
              originality, please contact request@CSIbizInfo.com.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Miscellaneous</div>

            <h3 class="history_box-h4">
              This Agreement shall be treated as though it were executed and
              performed in Dallas, Terxas, and shall be governed by and
              construed in accordance with the laws of the State of Texas
              (without regard to conflict of law principles). Any cause of
              action by you with respect to the Site (and/or any information,
              Documents, products or services related thereto) must be
              instituted within one (1) year after the cause of action arose or
              be forever waived and barred. The language in this Agreement shall
              be interpreted as to its fair meaning and not strictly for or
              against any party. This Agreement and all incorporated agreements
              and your information may be automatically assigned by us in our
              sole discretion to a third party in the event of an acquisition,
              sale or merger. Should any part of this Agreement be held invalid
              or unenforceable, that portion shall be construed consistent with
              applicable law and the remaining portions shall remain in full
              force and effect. To the extent that anything in or associated
              with the Site is in conflict or inconsistent with this Agreement,
              this Agreement shall take precedence. Our failure to enforce any
              provision of this Agreement shall not be deemed a waiver of such
              provision nor of the right to enforce such provision. Our rights
              under this Agreement shall survive any termination of this
              Agreement.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Arbitration</div>

            <h3 class="history_box-h4">
              Any legal controversy or legal claim arising out of or relating to
              this Agreement or our services, excluding legal action taken by us
              to collect or recover damages for, or obtain any injunction
              relating to, Site operations, intellectual property, and our
              services, shall be settled solely by binding arbitration in
              accordance with the commercial arbitration rules of JAMS. Any such
              controversy or claim shall be arbitrated on an individual basis,
              and shall not be consolidated in any arbitration with any claim or
              controversy of any other party. The arbitration shall be conducted
              in San Francisco, California, and judgment on the arbitration
              award may be entered into any court having jurisdiction thereof.
              Either you or we may seek any interim or preliminary relief from a
              court of competent jurisdiction in Dallas, Texas necessary to
              protect the rights or property of you and us pending the
              completion of arbitration. Each party shall bear one-half of the
              arbitration fees and costs incurred through JAMS.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">Force Majeure</div>

            <h3 class="history_box-h4">
              “CSIbizInfo” will not be liable for failure to perform any
              obligation under this agreement if such failure is caused by the
              occurrence of any unforeseen contingency or circumstances beyond
              the reasonable control of “CSIbizInfo”, including without
              limitation Internet outages, communications outages, fire, flood
              war or an act of God.
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeNames: "",
      attentionDialog: false, //弹框
    };
  },
  computed: {},
  methods: {},
  created() {},
};
</script>
<style scoped>
.title {
  font-family: 'DMSans Bold';
  margin-top: 1.5714285714285714rem;
  margin-bottom: 1.4285714285714286rem;
}
.el-collapse {
  border: none !important;
  font-family: "Arial";
}
.item_title /deep/ .el-collapse-item__header {
  font-size: 1.4285714285714286rem;
  color: #022955;
  font-family: "Arial";
  /* margin: 0.7142857142857143rem 0; */
  height: 1.4285714285714286rem;
  background-color: #f5f5f5;
  border: none !important;
}
.item_title {
  padding: 1.4285714285714286rem;
  background-color: #f5f5f5;
  margin-bottom: 0.7142857142857143rem;
}
.item_title.el-collapse-item.is-active:last-child
  /deep/
  .el-collapse-item__wrap,
.item_title:last-child /deep/ .el-collapse-item__header {
  border-width: 0;
  border: none !important;
}
.item_title /deep/ .el-collapse-item__header.is-active {
  border-bottom-color: transparent;
}
.item_cont_title {
  /* font-family: "Arial Bold"; */
  font-family: "Arial";
  /* margin-bottom: 0.7142857142857143rem; */
  color: #022955;
}
.item_cont_txt {
  font-size: 1.2857142857142858rem;
  color: #1a2332;
  background-color: #f5f5f5;
  padding-top: 1.1428571428571428rem;
}
.title_txt img {
  width: 1.4285714285714286rem;
  margin-right: 0.5714285714285714rem;
}
.item_title /deep/.el-collapse-item__content {
  padding-bottom: 0;
}

/* .item_title /deep/ .el-collapse-item__arrow {
    width: 1.7142857142857142rem;
    height: 1.7142857142857142rem;
    background: #ffffff;
    border-radius: 0.5714285714285714rem;
    border: 0.07142857142857142rem solid #e7eaed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 0.6428571428571429rem;
} */
/* .item_title /deep/ .el-collapse-item__arrow::before {
    content: '';
}
*/
.item_title /deep/ .el-collapse-item__arrow {
  transform: rotate(90deg);
}

.item_title /deep/ .el-collapse-item__arrow.is-active {
  transform: rotate(-90deg);
}

.flex_center_between_box {
  display: flex;
  justify-content: space-between;
}
.link {
  color: #1290c9;
  text-decoration: underline;
}
.head-text {
  font-family: 'DMSans Bold';
  font-size: 1.4285714285714286rem !important;
  text-align: left;
  font-style: normal;
  color: #1a2332;
  line-height: 1.6428571428571428rem;
}
.icon-time {
  font-size: 1.1428571428571428rem;
  line-height: 1.6428571428571428rem;
  color: #999999;
}
.message-right {
  margin-left: 1.4285714285714286rem;
}
ul {
  background-color: #f5f5f5;
}
/deep/ .el-collapse-item__wrap {
  border: none !important;
}
.history_box-h3 {
  font-size: 1.2857142857142858rem;
  color: #666666;
  line-height: 1.8571428571428572rem;
  margin: 1.4285714285714286rem 0rem;
}
.history_box-h4 {
  font-size: 1.2857142857142858rem;
  color: #666666;
  line-height: 1.8571428571428572rem;
  margin-top: 0.7142857142857143rem;
}
.item_cont_txt-head {
  font-weight: normal;
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  font-family: 'DMSans Bold';
}
.history_box-head{
    margin-bottom: 1.4285714285714286rem;
}
</style>